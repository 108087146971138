// import React from "react"
import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

// export default IndexPage

import React from "react"
import styled from "styled-components"
import Page from "../components/Page"
import SEO from "../components/seo"
import Logo from "../components/Logo"
import Button from "../components/Button"
import { Input, TextArea } from "../components/Inputs"
import P from "../components/styles/P"
import Container, { ContainerLeft } from "../components/styles/Container"
import Spacer from "../components/styles/Spacer"
import Center from "../components/styles/Center"
import Flex from "../components/styles/Flex"
import Fade from "../components/styles/Fade"
import Span from "../components/styles/Span"
import Column from "../components/styles/Column"
import Row from "../components/styles/Row"
import Image from "../components/styles/Image"
import FullHeightSection from "../components/styles/FullHeightSection"
import THEME from "../theme"
import FormContainer from "../components/styles/FormContainer"
import StyledLink from "../components/styles/StyledLink"
import contactImage from "../images/contact.png"

export default class Home extends React.Component {
  // state = {
  //   name: "",
  //   email: "",
  //   project: "",
  // }

  constructor(props) {
    super(props)
    this.contactPage = React.createRef()
  }

  scrollToContact = () => {
    window.scrollTo({
      top: this.contactPage.current.offsetTop,
      behavior: "smooth",
    })
  }

  // handleSubmit = async e => {
  //   e.preventDefault()
  //   const { name, email, project } = this.state
  //   this.setState({ isLoading: true })
  //   const res = await fetch("/.netlify/functions/contact", {
  //     body: JSON.stringify({ email, name, project }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //   })
  //   if (res.error || !res.ok) {
  //     this.setState({ isLoading: false, hasSubmitError: true })
  //   } else {
  //     this.setState({ isLoading: false, isSubmitted: true })
  //     this.resetForm()
  //   }
  //   setTimeout(() => {
  //     this.setState({ isSubmitted: false, hasSubmitError: false })
  //   }, 2500)
  // }

  // resetForm = () => {
  //   this.setState({ name: "", email: "", project: "" })
  // }

  // handleFormUpdate = e => {
  //   this.setState({
  //     [e.target.id]: e.target.value,
  //   })
  // }

  render() {
    return (
      <Page>
        <SEO
          title="Matthew Knudsen Bethany Sarica Wedding 2022 Boston"
          description="Wedding of Matthew Knudsen and Bethany Sarica 2022 Boston August 13 Langham Hotel. Some buzzwords for google: 
          matthew knudsen bethany sarica wedding, matthew knudsen wedding 2022, bethany sarica wedding 2022"
        />
        <FullHeightSection>
          <Center>
            <Container maxWidth={280}>
              <h2>
                Matthew Knudsen and Bethany Sarica are getting married!
                at the Langham Hotel in Boston, MA
                August 13, 2022
              </h2>
              <P alignment={P.ALIGNMENT.CENTER}>
                If you're looking for the real wedding website, it's here on Minted:{" "}
              </P>
              <P alignment={P.ALIGNMENT.CENTER}>
              <StyledLink
                  href="https://theknudsens.minted.us"
                  target="_blank"
                >
                  theknudsens.minted.us
                </StyledLink>
              </P>
            </Container>
          </Center>
        </FullHeightSection>
        <Spacer size={30} />
        <Spacer size={500} />
      </Page>
    )
  }
}
